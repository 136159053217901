import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Nav, Navbar, Dropdown, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret';
import { faPassport } from '@fortawesome/free-solid-svg-icons/faPassport';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faUsersLine } from '@fortawesome/free-solid-svg-icons/faUsersLine';

import { useUserSelector } from 'services/UserSelectorContext';

import SignOutButton from 'components/SignOutButton';

const LanguageWrapper = styled.div`
  margin-top: -3px;
  margin-right: 4px;

  .dropdown-toggle::after {
    display: none !important;
  }
`;

const AdminLogo = styled.div`
  position: relative;
  right: -67px;
  top: -7px;
  font-size: 1rem;
`;

function Menu() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, isLoggedIn, user } = useUserSelector();
  const { pathname } = useLocation();
  const flagImg = useMemo(() => {
    switch (i18n.language) {
      case 'es':
        return 'es.png';
      default:
        return 'en.png';
    }
  }, [i18n.language]);

  if (isLoading) {
    return null;
  }

  return (
    <Navbar bg="primary" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src="/logo.png" width="120px" alt="logo" />
          <AdminLogo>ADMIN</AdminLogo>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {isLoggedIn ? (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto justify-content-end">
              <Nav.Link onClick={() => navigate('/')} active={pathname === '/'}>
                <FontAwesomeIcon icon={faHome} /> {t('Home')}
              </Nav.Link>
              <Nav.Link onClick={() => navigate('/users')} active={pathname === '/users'}>
                <FontAwesomeIcon icon={faUser} /> {t('Users')}
              </Nav.Link>
              <Nav.Link onClick={() => navigate('/guests')} active={pathname === '/guests'}>
                <FontAwesomeIcon icon={faUserSecret} /> {t('Guests')}
              </Nav.Link>
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faUsersLine} /> {t('Referrals')}
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item onClick={() => navigate('/referrals/logs')}>
                  Referral Logs
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/referrals/earnings')}>
                  Referral Earnings
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/referrals/earnings/review-requests')}>
                  Referral Earnings Review Requests
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/referrals/earnings/withdrawals')}>
                  Referral Withdrawals
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                onClick={() => navigate('/reference-checks')}
                active={pathname === '/reference-checks'}
              >
                <FontAwesomeIcon icon={faUserSecret} /> {t('Reference checks')}
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate('/passport-requests')}
                active={pathname === '/passport-requests'}
              >
                <FontAwesomeIcon icon={faPassport} /> {t('Passport requests')}
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate('/partner-companies')}
                active={pathname === '/partner-companies'}
              >
                <FontAwesomeIcon icon={faBuilding} /> {t('Partner companies')}
              </Nav.Link>
            </Nav>

            <Navbar.Text className="d-flex">
              <LanguageWrapper>
                <Dropdown size="sm">
                  <Dropdown.Toggle split id="dropdown-basic">
                    <img src={`/${flagImg}`} alt="vector" width="22" role="button" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => i18n.changeLanguage('en')}>
                      <div>
                        <img src="/en.png" alt="vector" width="22" role="button" />
                        &nbsp;
                        <span className="text-black mt-1">English</span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => i18n.changeLanguage('es')}>
                      <div>
                        <img src="/es.png" alt="vector" width="22" role="button" />
                        &nbsp;
                        <span className="text-black mt-1">Español</span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </LanguageWrapper>

              <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <i>{user.email}</i>&nbsp;
                <SignOutButton />
              </div>
            </Navbar.Text>
          </Navbar.Collapse>
        ) : null}
      </Container>
    </Navbar>
  );
}

export default Menu;
